import React, { Component } from 'react';
import './ResultPage.css';
import {Col, Container, Modal, Row} from "react-bootstrap";
import Select, { components } from 'react-select';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    fetchInsurancePlans,
    saveDataFromMainPageForm,
    saveInsurancePlanDataForGetPlan,
    saveInsurancePlanDataForViewPlan,
    clearInsurancePlansArrayBeforeFetching,
    changePaymentFrequencyValue,
    clearInsurancePlanDataForViewPlan,
    changeFilterOption,
    saveInsurancePlansDataForComparePlans,
    sendGatingDataToEmail, changeCurrency, changeCurrencyChangeAvailability, changeSorting, changeCurrencyChangeValue
} from "../../../actions/actions";
import {
    renderTypeOfCoverageList,
    calculateFinalPrice,
    renderGuaranteeLevelNameTag,
    renderInsuranceCompanyLogo,
    generateFormattedPrice,
    getAdultsAmount,
    emailInputValidation, detectDollarCountry, detectCurrency
} from "../../../global-app-elements/global_helper_functions";
import {
    INSURANCE_CFE_OPTIONS,
    NATIONALITY_OPTIONS,
    AGE_OPTIONS,
    DESTINATION_COUNTRY_OPTIONS,
    INSURANCE_DURATION_OPTIONS,
    PAYMENT_FREQUENCY_OPTIONS, PAYMENT_CURRENCY_OPTIONS, SORT_OPTIONS
} from "../../../global-app-elements/global_selects_options";



import ViewPlan from "../../ModalsBody/ViewPlan/ViewPlan";
import Tooltip from "../../GlobalComponents/Tooltip/Tooltip";
import gatingIcon from "../../../assets/images/gating.svg";
import {GAevent, modalViewGA, pageViewGA} from "../../../global-app-elements/ga-functions";
import ComparesPlan from "../../ModalsBody/ComparePlans/ComparesPlan";
import Lottie from 'react-lottie'
import ServerTimeoutAnimation from "../../../assets/Book.json";
import warning from '../../../assets/images/warning.svg';
import {Trans} from 'react-i18next';
import i18n from 'i18next';
const cloneDeep = require('clone-deep');
const queryString = require('query-string');














const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};






class ResultPage extends Component {


    constructor(props) {

        window.scrollTo(0, 0);

        super(props);


        // Якщо користувач потрапляє на цю сторінук без даних форми з першої main page,
        // юзера редіректить на першу сторінку автоматично


        // перевірка URL параметрів форми і обробка їх
        const parsedUrlParameters = queryString.parse(props.location.search);
        let personsArrayFromUrl = parsedUrlParameters.p;

        if ( personsArrayFromUrl ) {

            let insuree_nationality;
            let insuree_age;
            let dependents_array = [];

            if ( Array.isArray(personsArrayFromUrl) ) {

                insuree_nationality = personsArrayFromUrl[0].split(',')[0];
                insuree_age = personsArrayFromUrl[0].split(',')[1];

                for (let i = 1; i < personsArrayFromUrl.length; i++) {

                    dependents_array.push({
                        nationality: personsArrayFromUrl[i].split(',')[0],
                        age: personsArrayFromUrl[i].split(',')[1]
                    });

                }

            } else {

                insuree_nationality = personsArrayFromUrl.split(',')[0];
                insuree_age = personsArrayFromUrl.split(',')[1];

            }

            if (
                DESTINATION_COUNTRY_OPTIONS.filter(option => option.value === parsedUrlParameters.dc).length > 0 &&
                INSURANCE_DURATION_OPTIONS.filter(option => option.value === parsedUrlParameters.dur).length > 0 &&
                INSURANCE_CFE_OPTIONS.filter(option => option.value === parsedUrlParameters.cfe).length > 0 &&
                NATIONALITY_OPTIONS.filter(option => option.value === insuree_nationality).length > 0 &&
                AGE_OPTIONS.filter(option => option.value === insuree_age).length > 0
            ) {

                let insurees_data = {
                    insuree_object: {
                        nationality: insuree_nationality,
                        age: insuree_age
                    },
                    dependents_array: dependents_array,
                    destination_country: parsedUrlParameters.dc,
                    insurance_duration: parsedUrlParameters.dur,
                    insuree_has_cfe: parsedUrlParameters.cfe
                };

                console.log('User has valid URL parameters !');

                // пропустити юзера на наших воротах
                localStorage.setItem('gate_closed', 'false');

                props.onSaveDataFromMainPageForm( insurees_data );

            } else {

                console.log('User has NOT valid URL parameters !');

                if( !this.props.insurees_data ) {

                    this.props.history.push('/');

                }

            }

        } else {

            if( !this.props.insurees_data ) {

                this.props.history.push('/');

            }

        }



        this.state = {
            insurees_data: this.props.insurees_data,
            started_insurance_plans_array: this.props.started_insurance_plans_array,
            insurance_plan_selected_filters_values: this.props.filters_options_object,
            final_insurance_plans_array_after_sorting: null,
            form_changes_detected: false,
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null,
            gating_input_validation_error: false,
            maximized_plans_array: [],
            currency_change: this.props.currency_availability
        };


        this.handleChangeNationality = this.handleChangeNationality.bind(this);
        this.handleChangeAge = this.handleChangeAge.bind(this);
        this.handleChangeDependentsNationality = this.handleChangeDependentsNationality.bind(this);
        this.handleChangeDependentsAge = this.handleChangeDependentsAge.bind(this);
        this.handleChangeDestinationCountry = this.handleChangeDestinationCountry.bind(this);
        this.handleChangeInsuranceDuration = this.handleChangeInsuranceDuration.bind(this);
        this.handleChangeInsureeHasCfe = this.handleChangeInsureeHasCfe.bind(this);
        this.handleDeleteDependentItem = this.handleDeleteDependentItem.bind(this);
        this.handleAddDependentItem = this.handleAddDependentItem.bind(this);

        this.handleChangeActivePriceOptionsType = this.handleChangeActivePriceOptionsType.bind(this);
        this.handleChangeCoinsuranceOption = this.handleChangeCoinsuranceOption.bind(this);
        this.handleChangeDeductibleOption = this.handleChangeDeductibleOption.bind(this);
        this.handleChangePaymentFrequencyOption = this.handleChangePaymentFrequencyOption.bind(this);
        this.handleChangePaymentCurrencyOption = this.handleChangePaymentCurrencyOption.bind(this);
        this.handleChangeSortingOption = this.handleChangeSortingOption.bind(this);
        this.handleChangePaymentCurrencyValueOption = this.handleChangePaymentCurrencyValueOption.bind(this);
        this.handleChangeGuaranteeLevelFilter = this.handleChangeGuaranteeLevelFilter.bind(this);
        this.handleChangeInsuranceCompanyFilter = this.handleChangeInsuranceCompanyFilter.bind(this);
        this.handleChangeTypeOfCoverageFilter = this.handleChangeTypeOfCoverageFilter.bind(this);
        this.handleShowMaximizedPlanVersion = this.handleShowMaximizedPlanVersion.bind(this);

        this.generateFormattedCoinsuranceOptions = this.generateFormattedCoinsuranceOptions.bind(this);
        this.generateFormattedDeductibleOptions = this.generateFormattedDeductibleOptions.bind(this);
        this.generateTableStyleIdForCompare = this.generateTableStyleIdForCompare.bind(this);
        this.generateUrlWithMainFormData = this.generateUrlWithMainFormData.bind(this);
        this.handleGetPlan = this.handleGetPlan.bind(this);
        this.handleUpdateInsurancePlans = this.handleUpdateInsurancePlans.bind(this);
        this.handleMobileApplyChangesOfFilters = this.handleMobileApplyChangesOfFilters.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleUncheckAllComparePlans = this.handleUncheckAllComparePlans.bind(this);
        this.handleGatingButton = this.handleGatingButton.bind(this);

        this.generateCurrentPrice = this.generateCurrentPrice.bind(this);

        this.renderInsurancePlans = this.renderInsurancePlans.bind(this);

        // REFS INIT
        this.gatingInput = React.createRef();

    }



    componentDidMount() {

        // TRIGGER PAGE VIEW FOR GOOGLE ANALYTICS
        pageViewGA('insurance');

        if ( !this.props.started_insurance_plans_array && this.props.insurees_data ) {

            this.props.onFetchInsurancePlans(this.props.insurees_data);

        }

        // перевірка чи юзер пройшов ворота наші
        const gate_closed = localStorage.getItem('gate_closed');

        if ( gate_closed === 'true' ) {
            GAevent("gate shown", "test", "test");
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: "gate_shown",
                gate_shown: true   // <--------
            })
            this.handleShowModal('gating-modal');

        }

        switch (this.props.insurees_data.destination_country){
            case "États-Unis d’Amérique":
            case "Thaïlande":
            case "Vietnam":
            case "Singapour":
            case "Hong Kong":
            case "Chine":
            case "Japon":
            case "Australie":
            case "Nouvelle-Zélande":
            case "Corée du Sud":
            case "Taïwan":
            case "Malaisie":
            case "Laos":
            case "Cambodge":
            case "Myanmar":
                if (this.props.currency !== "USD") {
                    this.props.onChangeCurrency("USD");
                    console.log("Currency changed to USD")
                }
                break;
            default:
                if (this.props.currency !== "EUR") {
                    this.props.onChangeCurrency("EUR");
                    console.log("Currency changed to EUR")
                }
                break;
        }

        if ( this.state.started_insurance_plans_array){

            this.insurancePlansArrayUpdatingToFinalArray();

        }

    }


    componentDidUpdate(prevProps, prevState) {

        // Оновлюється валюта якшо людині змінила країну призначення
        if (prevProps.insurees_data.destination_country !== this.props.insurees_data.destination_country && this.props.started_insurance_plans_array !== null) {

            switch (this.props.insurees_data.destination_country) {
                case "États-Unis d’Amérique":
                case "Thaïlande":
                case "Vietnam":
                case "Singapour":
                case "Hong Kong":
                case "Chine":
                case "Japon":
                case "Australie":
                case "Nouvelle-Zélande":
                case "Laos":
                case "Corée du Sud":
                case "Taïwan":
                case "Malaisie":
                case "Cambodge":
                case "Myanmar":
                    if (this.props.currency !== "USD") {
                        this.props.onChangeCurrency("USD");
                        this.handleChangePaymentCurrencyValueOption("USD");
                        console.log("Currency changed to USD")
                    }
                    break;
                default:
                    if (this.props.currency !== "EUR") {
                        this.props.onChangeCurrency("EUR");
                        this.handleChangePaymentCurrencyValueOption("EUR");
                        console.log("Currency changed to EUR")
                    }
                    break;
            }

        }

        // Якщо користувач онови Travel info data , то прапорець змінився на true
        if (prevState.insurees_data && prevState.insurees_data !== this.state.insurees_data ) {

            this.setState({ form_changes_detected: true });

            // this.handleChangePaymentCurrencyValueOption(null);

        }


        // Якщо fetchInsurancePlans ( запит до API ) повернув список планів ,
        // то вони зчитуються у локальний state і робиться re-render і відображається цей список планів
        if (prevProps.started_insurance_plans_array !== this.props.started_insurance_plans_array) {

            this.setState({
                started_insurance_plans_array: this.props.started_insurance_plans_array,
                insurees_data: this.props.insurees_data
            }, () => {
                window.scrollTo(0, 0);
            });

        }


        if (this.props.started_insurance_plans_array !== null) {

            if (
                prevProps.filters_options_object !== this.props.filters_options_object
                || prevState.started_insurance_plans_array !== this.state.started_insurance_plans_array
                || prevProps.sort !== this.props.sort
                || prevProps.payment_frequency_value !== this.props.payment_frequency_value
                || prevProps.has_the_curency_value_changed !== this.props.has_the_curency_value_changed && this.props.has_the_curency_value_changed !== null
            ) {

                this.insurancePlansArrayUpdatingToFinalArray();

            }

        }

        // Якщо користувач клікнув на Gat Plan , таким чином він оновив selected plan in global Redux Store
        // цей рядок коду слухає цю зміну, і кидає користувача на сторінку Gat Plan Page , якщо це сталося
        if (prevProps.get_plan_data !== this.props.get_plan_data) {

            this.props.history.push('/get-plan');

        }


        // Якщо користувач клікнув на View Plan , таким чином він оновив selected plan in global Redux Store
        // цей рядок коду слухає цю зміну, і кидає користувача на сторінку Gat Plan Page , якщо це сталося
        if (prevProps.view_plan_data !== this.props.view_plan_data) {

            this.handleShowModal('view-plan');

        }


        // Якщо користувач оновив Travel info data в Global Store то слід зробити новий запит до API
        if (prevProps.insurees_data !== this.props.insurees_data) {

            this.props.onClearInsurancePlansArrayBeforeFetching();
            this.props.onFetchInsurancePlans(this.props.insurees_data);

        }

        if (prevProps.server_timeout !== this.props.server_timeout) {

            if (this.props.server_timeout === true) {
                this.handleShowModal('warning-server-timeout');
            } else {
                this.handleCloseModal();
            }
        }


    }

    insurancePlansArrayUpdatingToFinalArray() {

        let plans_array_after_filtering = this.generateCurrentPrice();

        let resulted_sorted_plans_array_after_filtering = this.insurancePlansArraySorting(plans_array_after_filtering);

        this.setState({
            final_insurance_plans_array_after_sorting: resulted_sorted_plans_array_after_filtering,
        });

        setTimeout(() => {
            this.setState({
                hidden_insurance_plans_array_after_sorting: document.querySelectorAll(".insurancePlanRow.hide").length
            });
        }, 50)
    }

    insurancePlansArraySorting(array){

        let resulted_new_sorted_plans_array_after_filtering;

        if (this.props.sort === 'low') {
            resulted_new_sorted_plans_array_after_filtering = array.sort((a, b) => a.defaultPrice - b.defaultPrice);
        } else if (this.props.sort === 'high') {
            resulted_new_sorted_plans_array_after_filtering = array.sort((a, b) => b.defaultPrice - a.defaultPrice);
        }

        return resulted_new_sorted_plans_array_after_filtering;

    }

    // ЦЕ ВСЕ ТУПО КОПІЮЄТЬСЯ З MAIN PAGE ТРЕАБ ОБ*ЄДНАТИ ЦІ ФУНЦІЇ І ВИНЕСТИ ГЛОБАЛЬНО START

    handleChangeNationality(selected_option) {


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    nationality: selected_option.value
                }
            }
        }));

    };


    handleChangeAge(selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let current_insuree_age = this.state.insurees_data.insuree_object.age;
        let selected_value;

        if ( selected_option.value > 26 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_insuree_age === null || current_insuree_age <= 26 )) {

                this.handleShowModal('warning-message-adults-amount');
                selected_value = null;

            } else {

                selected_value = selected_option.value;

            }

        } else {

            selected_value = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    age: selected_value
                }
            }
        }));

    };


    handleChangeDependentsNationality(position_in_list , selected_option) {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array[position_in_list].nationality = selected_option.value;


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDependentsAge(position_in_list, selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let dependents_array = [...this.state.insurees_data.dependents_array];
        let current_dependent_age = dependents_array[position_in_list].age;

        if ( selected_option.value > 26 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_dependent_age === null || current_dependent_age <= 26 )) {

                this.handleShowModal('warning-message-adults-amount');
                dependents_array[position_in_list].age = null;

            } else {

                dependents_array[position_in_list].age = selected_option.value;

            }

        } else {

            dependents_array[position_in_list].age = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDestinationCountry(selected_option) {

        let main_insuree_nationality = this.state.insurees_data.insuree_object.nationality;
        let destination_country;

        if ( selected_option.value === main_insuree_nationality ) {

            this.handleShowModal('warning-message-expatriation-country');

            destination_country = null;

        } else {

            destination_country = selected_option.value;

        }

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                destination_country: destination_country
            }
        }));

    };


    handleChangeInsuranceDuration(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insurance_duration: selected_option.value
            }
        }));

    };


    handleAddDependentItem() {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.push({
            nationality: this.state.insurees_data.insuree_object.nationality,
            age: null
        });


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));


    }


    handleDeleteDependentItem(position_in_list) {


        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.splice(position_in_list, 1);


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    }


    renderDependentsList() {

        if ( this.state.insurees_data ) {

            let dependents_array = this.state.insurees_data.dependents_array;
            let insurees_data = this.state.insurees_data;


            if (dependents_array.length > 0) {

                return this.state.insurees_data.dependents_array.map((item, index) => {

                    return (

                        <Row key={index} className="dependentsItemRow">
                            <Col xs={6} className={(insurees_data.dependents_array[index].nationality) ? "is-valid-input" : "not-valid-input" }>
                                <div className="inputBox">
                                    <Select
                                        value={NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].nationality)}
                                        defaultValue={0}
                                        components={{DropdownIndicator}}
                                        positionInList={0}
                                        className="nat-select"
                                        classNamePrefix="insurance-main-select"
                                        onChange={this.handleChangeDependentsNationality.bind(this, index)}
                                        options={NATIONALITY_OPTIONS}
                                    />
                                </div>
                            </Col>

                            <Col xs={4} className={(insurees_data.dependents_array[index].age) ? "is-valid-input" : "not-valid-input" }>
                                <div className="inputBox">
                                    <Select
                                        value={AGE_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].age)}
                                        placeholder="0"
                                        components={{DropdownIndicator}}
                                        classNamePrefix="insurance-main-select"
                                        onChange={this.handleChangeDependentsAge.bind(this, index)}
                                        options={AGE_OPTIONS}
                                    />
                                </div>
                            </Col>

                            <Col xs={2}>
                                <button className="deleteDependentButton"
                                        onClick={this.handleDeleteDependentItem.bind(this, index)}>
                                    <span className="icon-trash"></span>
                                </button>
                            </Col>
                        </Row>

                    );

                });

            }

        }

    }

    // ЦЕ ВСЕ ТУПО КОПІЮЄТЬСЯ З MAIN PAGE ТРЕАБ ОБ*ЄДНАТИ ЦІ ФУНЦІЇ І ВИНЕСТИ ГЛОБАЛЬНО END




    handleChangeInsureeHasCfe(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_has_cfe: selected_option.value
            }
        }));

    };


    handleChangeActivePriceOptionsType(plan_options_type, plan_id) {

        let result_insurance_plans_array = [...this.state.started_insurance_plans_array];

        //TODO: можливо замінити filter на цикл, щоб не виникло траблів в майбутньому
        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        current_plan_array[0].selected_radio_option = plan_options_type;

        switch (plan_options_type) {
            case 'coinsurance':
                current_plan_array[0].deductible_selected_object = null;
                break;
            case 'deductible':
                current_plan_array[0].coinsurance_selected_object = null;
                break;
            default:
                current_plan_array[0].coinsurance_selected_object = null;
                current_plan_array[0].deductible_selected_object = null;
        }


        //TODO: оновлювати лише 1 план , а не усі
        this.setState({
            started_insurance_plans_array: result_insurance_plans_array
        });

    }


    handleChangeCoinsuranceOption(plan_id, selected_option) {

        let result_insurance_plans_array = [...this.state.started_insurance_plans_array];

        //TODO: можливо замінити filter на цикл, щоб не виникло траблів в майбутньому
        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        current_plan_array[0].deductible_selected_object = null;
        current_plan_array[0].coinsurance_selected_object = selected_option;

        //TODO: оновлювати лише 1 план , а не усі
        this.setState({
            started_insurance_plans_array: result_insurance_plans_array
        });

    }


    handleChangeDeductibleOption(plan_id, selected_option)  {

        let result_insurance_plans_array = [...this.state.started_insurance_plans_array];

        //TODO: можливо замінити filter на цикл, щоб не виникло траблів в майбутньому
        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        current_plan_array[0].coinsurance_selected_object = null;
        current_plan_array[0].deductible_selected_object = selected_option;

        //TODO: оновлювати лише 1 план , а не усі
        this.setState({
            started_insurance_plans_array: result_insurance_plans_array
        });

    }



    handleChangePaymentFrequencyOption(selected_option)  {

        this.props.onChangePaymentFrequencyValue(selected_option.value)

    }

    handleChangePaymentCurrencyOption(selected_option)  {

        if (selected_option !== this.props.currency) {
            this.props.onChangeCurrency(selected_option.value);
            this.handleChangePaymentCurrencyValueOption(selected_option.value);
        }

    }

    handleChangeSortingOption(selected_option)  {

        this.props.onChangeSorting(selected_option.value)

    }

    handleChangePaymentCurrencyValueOption(selected_option)  {

        this.props.onChangeCurrencyChangeValue(selected_option)


    }


    handleChangeGuaranteeLevelFilter(selected_option) {

        this.props.onChangeFilterOption(
            'guarantee_level',
            selected_option.target.checked,
            selected_option.target.name
        );

    }


    handleChangeInsuranceCompanyFilter(selected_option) {

        this.props.onChangeFilterOption(
            'insurance_company',
            selected_option.target.checked,
            selected_option.target.name
        );

    }


    handleChangeTypeOfCoverageFilter(selected_option) {

        this.props.onChangeFilterOption(
            'type_of_coverage',
            selected_option.target.checked,
            selected_option.target.name
        );

    }


    handleUpdateInsurancePlans()  {

        // TODO: Зробити толкову валідацію

        let not_valid_inputs_array = document.querySelectorAll('.travelInfoCard .not-valid-input');

        console.log(not_valid_inputs_array.length);

        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            this.props.onSaveInsurancePlansDataForComparePlans([]);
            this.props.onSaveDataFromMainPageForm( this.state.insurees_data );
            this.setState({ form_changes_detected: false });

        }

    }


    handleMobileApplyChangesOfFilters() {

        if ( this.state.form_changes_detected ) {

            this.handleUpdateInsurancePlans();
            this.handleCloseModal();

        } else {

            this.handleCloseModal();

        }

    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({
            is_modal_visible: false,
            modal_content_type: null
        });

    }


    handleGetPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.state.started_insurance_plans_array);

        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        this.props.onSelectInsurancePlanDataObject(current_plan_array[0]);

    }


    handleViewPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.state.started_insurance_plans_array);

        if ( result_insurance_plans_array ) {

            let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

            this.props.onSaveInsurancePlanDataForViewPlan(current_plan_array[0]);

            // this.props.history.push('?view-plan='+plan_id);

        }

    }


    handleComparePlan(plan_id, selected_option) {

        let result_insurance_plans_array = cloneDeep(this.state.started_insurance_plans_array);
        let current_insurance_plans_array_for_compare = cloneDeep(this.props.compare_plans_local_array);

        if ( result_insurance_plans_array ) {


            if ( selected_option.target.checked ) {

                // добавляємо елемент в масив для compare plans якщо checkbox === true
                let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

                current_insurance_plans_array_for_compare.push(current_plan_array[0]);

                this.props.onSaveInsurancePlansDataForComparePlans(current_insurance_plans_array_for_compare);

            } else {

                // видаляємо елемент з масиву для compare plans якщо checkbox === false
                let new_plans_array = current_insurance_plans_array_for_compare.filter(option => option.plan_id !== plan_id);

                this.props.onSaveInsurancePlansDataForComparePlans(new_plans_array);

            }

        }

    }


    handleUncheckAllComparePlans() {

        this.props.onSaveInsurancePlansDataForComparePlans([]);

    }


    handleGatingButton() {

        let current_gating_value = this.gatingInput.current.value;

        if( emailInputValidation(current_gating_value) ) {

            this.setState({ gating_input_validation_error: false });
            this.handleCloseModal();

            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location.href]);
            _hsq.push(["identify",{
                email: current_gating_value,
                firstname: 'gated',
                lastname: 'email contact',
                plan_search_results_link: this.generateUrlWithMainFormData(),
                dur_e_du_contrat_souhait_: this.props.insurees_data.insurance_duration,
                adh_rent_cfe: this.props.insurees_data.insuree_has_cfe,
                leadin_pays_de_destination_bc1e211f71cc3f2e8346f8719273fb80:this.props.insurees_data.destination_country,
                leadin_pays_de_nationalitswb_0b819286eb1d5bab2234c31836f1e050: this.props.insurees_data.insuree_object.nationality,
                user_age_from_search: this.props.insurees_data.insuree_object.age,
                dependents_array: JSON.stringify(this.props.insurees_data.dependents_array)
            }]);
            _hsq.push(['trackPageView']);

            this.props.onSendGatingDataToEmail({
                insurees_data: this.props.insurees_data,
                user_gating_information: {
                    full_name: "Gated email",
                    email: current_gating_value,
                    results_link: this.generateUrlWithMainFormData()
                }
            });

        } else {

            this.setState({ gating_input_validation_error: true });

        }

    }


    handleShowMaximizedPlanVersion(plan_id) {

        let old_maximized_plans_array = cloneDeep(this.state.maximized_plans_array);
        let new_maximized_plans_array = old_maximized_plans_array.push(plan_id);

        console.log(old_maximized_plans_array);

        this.setState({ maximized_plans_array: old_maximized_plans_array } );

    }

    handleShowMinimizedPlanVersion(plan_id) {

        let old_maximized_plans_array = cloneDeep(this.state.maximized_plans_array);

        const index = old_maximized_plans_array.indexOf(plan_id);
        if (index > -1) {
            old_maximized_plans_array.splice(index, 1);
        }

        this.setState({ maximized_plans_array: old_maximized_plans_array } );

    }


    generateFormattedCoinsuranceOptions(options_values) {

        let new_coinsurance_options_array = [];

        options_values.forEach((item) => {
            new_coinsurance_options_array.push({
                value: item,
                label: item+'%'
            });
        });

        return new_coinsurance_options_array;

    }


    generateFormattedDeductibleOptions(options_values, destination_country, insurance_company) {

        // console.log(options_values)

        let new_deductible_options_array = [];

        // console.log(options_values);

        let sugnaEuroDeductibleArray = [0, 275, 550, 1100];

        if (insurance_company === "Cigna" && this.props.currency != "USD"){

            sugnaEuroDeductibleArray.forEach((item) => {
                new_deductible_options_array.push({
                    value: item,
                    label: item + detectDollarCountry(this.props.currency)
                });
            });

        } else {

            options_values.forEach((item) => {
                new_deductible_options_array.push({
                    value: item,
                    label: item + detectDollarCountry(this.props.currency)
                });
            });

        }

        return new_deductible_options_array;

    }


    generateTableStyleIdForCompare() {

        let compare_plans_local_array = this.props.compare_plans_local_array;

        switch (compare_plans_local_array.length) {
            case 1:
                return 'compare-table-one-col';
            case 2:
                return 'compare-table-two-col';
            case 3:
                return 'compare-table-three-col';
            default:
                return null ;
        }

    }


    generateUrlWithMainFormData() {


        if (this.state.insurees_data) {

            let urlWithMainFormData = window.location.host;

            urlWithMainFormData += this.props.location.pathname;
            urlWithMainFormData += '?p=' + this.state.insurees_data.insuree_object.nationality + ',' + this.state.insurees_data.insuree_object.age;

            this.state.insurees_data.dependents_array.forEach((item) => {

                urlWithMainFormData += '&p=' + item.nationality + ',' + item.age

            });

            urlWithMainFormData += '&dc=' + this.state.insurees_data.destination_country;
            urlWithMainFormData += '&dur=' + this.state.insurees_data.insurance_duration;
            urlWithMainFormData += '&cfe=' + this.state.insurees_data.insuree_has_cfe;

            console.log(urlWithMainFormData);

            return urlWithMainFormData;

        }
        // return urlWithMainFormData;

    }


    filteringInsurancePlansArray() {

        let result_insurance_plans_array = [...this.props.started_insurance_plans_array];
        let type_of_coverage_options_selected = this.props.filters_options_object.type_of_coverage;
        let guarantee_level_options_selected = this.props.filters_options_object.guarantee_level;
        let insurance_company_options_selected = this.props.filters_options_object.insurance_company;

        let plans_array_after_filtering = result_insurance_plans_array.filter(function(item) {


            // перевірка на "недоступні плани"
            if ( item.is_available !== "true" ) {

                // console.log("THe plan " + item.plan_id + " is not available because " + item.not_available_reason);
                return false;

            }

            let is_type_of_coverage_includes;
            let is_guarantee_level_includes = guarantee_level_options_selected.includes(item.guarantee_level_EA_displayed);
            let is_insurance_company_includes = insurance_company_options_selected.includes(item.insurance_company);


            let included_count = 0;

            type_of_coverage_options_selected.forEach(item_type => {

                if(item.coverage_types_included.includes(item_type)) {
                    included_count++;
                }

            });

            if (type_of_coverage_options_selected.length === included_count) {

                is_type_of_coverage_includes = true;

            } else {

                is_type_of_coverage_includes = false;

            }



            if ( is_guarantee_level_includes && is_insurance_company_includes && is_type_of_coverage_includes ) {
                return true;
            } else {
                return false;
            }

        });

        /**
         * the following code does ensure that only the plans that fully match currently selected filters
         * (most_relevant_plans) will go first in the search results, while others (less_relevant_plans) will follow even if they are cheaper
         */
        let coverage_types_string_from_filters = "";
        for (let filter_coverage_type of type_of_coverage_options_selected) {
            coverage_types_string_from_filters += filter_coverage_type + "+";
        }
        //remove the final plus
        coverage_types_string_from_filters = coverage_types_string_from_filters.substring(0, coverage_types_string_from_filters.length-1);
        let most_relevant_plans = [];
        let less_relevant_plans = [];
        for (let plan of plans_array_after_filtering) {
            // console.log("currently selected types of coverage:");
            // console.log(coverage_types_string_from_filters);

            if (plan.coverage_types_included === coverage_types_string_from_filters) {
                // console.log("plan " + plan.plan_id + " matches fully!");
                most_relevant_plans.push(plan);
            } else {
                less_relevant_plans.push(plan);
            }

        }

        //merge two arrays so that the most relevant plans go first
        plans_array_after_filtering = [...most_relevant_plans, ...less_relevant_plans];



        return plans_array_after_filtering;

    }


    generateCurrentPrice(){

        let plans_array_after_filtering = this.filteringInsurancePlansArray();

        let new_sorted_plans_array_after_filtering = plans_array_after_filtering.map((item_plan, index) => {

            let currentPrice = calculateFinalPrice(
                item_plan.insurance_company,
                this.props.payment_frequency_value,
                (item_plan.spreadsheet_rows) ? item_plan.spreadsheet_rows.length : 0,
                item_plan.spreadsheet_rows,
                item_plan.coverage_types_included,
                (item_plan.deductible_selected_object) ? item_plan.deductible_selected_object.value : 0,
                (item_plan.coinsurance_selected_object) ? item_plan.coinsurance_selected_object.value : 100,
                item_plan.destination_zone,
                item_plan.company_guarantee_level,
                this.props.currency,
                this.props.insurees_data.destination_country,
                item_plan.plan_id
            );

            item_plan.currentPrice = currentPrice["current_price"]*1;
            item_plan.defaultPrice = currentPrice["default_price"]*1;

            return item_plan;

        });


        return new_sorted_plans_array_after_filtering;

    }


    renderMobileFilterButton() {

        return (

            <div className="updateInsurancePlansButtonFixedBlock">
                <button className="mainButtonStyle updateInsurancePlansButton" onClick={this.handleMobileApplyChangesOfFilters}>
                    <Trans i18nKey="global_refresh"/>
                </button>
            </div>

        )

    }


    renderFiltersCardContent() {

        let selected_filters_options = this.props.filters_options_object;
        let insurees_data = this.state.insurees_data;


        return (

            <>
                <div className="filterCard planFiltersCard">
                    <div className="filterCardTitle"><Trans i18nKey={"coveregePage_filter_title"} /></div>

                    <Row>
                        <Col xs={12}>
                            <div className="inputBoxResultPage">
                                <div className="inputBoxTitle"><Trans i18nKey={"coveregePage_filter_needed_title"} /></div>

                                <label className="check-button-container">
                                    <input type="checkbox" disabled={true} defaultChecked={true} />
                                    <div className="check-button check-button--checkbox">
                                        <Trans i18nKey={"coveregePage_filter_needed_hospitalization"} />
                                        <Tooltip
                                            tooltipId="res_hosp"
                                            tooltipType="light"
                                            tooltipEffect="solid"
                                            tooltipIconSize={15}
                                            tooltipIconTopPosition={2}
                                            tooltipIconOffset={8}
                                            tooltipContent={i18n.t("resultPage_need_hospitalization_placeholder_content")}
                                        />
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="RC"
                                        defaultChecked={ (selected_filters_options.type_of_coverage.includes("RC") ? true : false )}
                                        onChange={this.handleChangeTypeOfCoverageFilter}
                                    />
                                    <div className="check-button check-button--checkbox"><Trans i18nKey={"coveregePage_filter_needed_healthcare"}/>
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="OD"
                                        defaultChecked={ (selected_filters_options.type_of_coverage.includes("OD") ? true : false )}
                                        onChange={this.handleChangeTypeOfCoverageFilter}
                                    />
                                    <div className="check-button check-button--checkbox"><Trans i18nKey={"coveregePage_filter_needed_optical"}/>
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="M"
                                        defaultChecked={ (selected_filters_options.type_of_coverage.includes("M") ? true : false )}
                                        onChange={this.handleChangeTypeOfCoverageFilter}
                                    />
                                    <div className="check-button check-button--checkbox">
                                        <Trans i18nKey={"coveregePage_filter_needed_maternity"}/>
                                        <Tooltip
                                            tooltipId="res_mater"
                                            tooltipType="light"
                                            tooltipEffect="solid"
                                            tooltipIconSize={15}
                                            tooltipIconTopPosition={2}
                                            tooltipIconOffset={8}
                                            tooltipContent={i18n.t("resultPage_need_maternity_placeholder_content")}
                                        />
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="inputBoxResultPage">
                                <div className="inputBoxTitle"><Trans i18nKey={"coveregePage_filter_level_title"}/></div>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Basic"
                                        defaultChecked={ (selected_filters_options.guarantee_level.includes("Basic") ? true : false )}
                                        onChange={this.handleChangeGuaranteeLevelFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Basic
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Comfort"
                                        defaultChecked={ (selected_filters_options.guarantee_level.includes("Comfort") ? true : false )}
                                        onChange={this.handleChangeGuaranteeLevelFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Comfort
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="VIP"
                                        defaultChecked={ (selected_filters_options.guarantee_level.includes("VIP") ? true : false )}
                                        onChange={this.handleChangeGuaranteeLevelFilter}
                                    />
                                    <div className="check-button check-button--checkbox">VIP
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="inputBoxResultPage">
                                <div className="inputBoxTitle"><Trans i18nKey={"coveregePage_filter_company_title"}/></div>

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="April"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("April") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">April
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                {(process.env.REACT_APP_LANGUAGE === "FR") ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Allianz"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("Allianz") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Allianz
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="MSH"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("MSH") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">MSH
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label>

                                {(process.env.REACT_APP_LANGUAGE === "FR") ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Henner"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("Henner") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Henner
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                {((process.env.REACT_APP_LANGUAGE === "FR") || (process.env.REACT_APP_LANGUAGE === "ES")) ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Assur Travel"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("Assur Travel") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Assur Travel
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                {((process.env.REACT_APP_LANGUAGE === "FR") || (process.env.REACT_APP_LANGUAGE === "ES")) ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="BUPA International"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("BUPA International") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">BUPA International
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                {((process.env.REACT_APP_LANGUAGE === "FR") || (process.env.REACT_APP_LANGUAGE === "ES")) ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Palig"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("Palig") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Palig International
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                {((process.env.REACT_APP_LANGUAGE === "FR") || (process.env.REACT_APP_LANGUAGE === "ES")) ? <label className="check-button-container">
                                    <input
                                        type="checkbox"
                                        name="Bupa Nacional Vital"
                                        defaultChecked={ (selected_filters_options.insurance_company.includes("Bupa Nacional Vital") ? true : false )}
                                        onChange={this.handleChangeInsuranceCompanyFilter}
                                    />
                                    <div className="check-button check-button--checkbox">Bupa Nacional Vital
                                        <div className="check-button__indicator">
                                            <span className="icon-check-mark"></span>
                                        </div>
                                    </div>
                                </label> : null }

                                {/*<label className="check-button-container">*/}
                                {/*    <input*/}
                                {/*        type="checkbox"*/}
                                {/*        name="Liberty"*/}
                                {/*        defaultChecked={ (selected_filters_options.insurance_company.includes("Liberty") ? true : false )}*/}
                                {/*        onChange={this.handleChangeInsuranceCompanyFilter}*/}
                                {/*    />*/}
                                {/*    <div className="check-button check-button--checkbox">Liberty*/}
                                {/*        <div className="check-button__indicator">*/}
                                {/*            <span className="icon-check-mark"></span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</label>*/}

                                {/*<label className="check-button-container">*/}
                                {/*    <input*/}
                                {/*        type="checkbox"*/}
                                {/*        name="PTI"*/}
                                {/*        defaultChecked={ (selected_filters_options.insurance_company.includes("PTI") ? true : false )}*/}
                                {/*        onChange={this.handleChangeInsuranceCompanyFilter}*/}
                                {/*    />*/}
                                {/*    <div className="check-button check-button--checkbox">PTI*/}
                                {/*        <div className="check-button__indicator">*/}
                                {/*            <span className="icon-check-mark"></span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</label>*/}

                                {/*<label className="check-button-container">*/}
                                {/*    <input*/}
                                {/*        type="checkbox"*/}
                                {/*        name="Cigna"*/}
                                {/*        defaultChecked={ (selected_filters_options.insurance_company.includes("Cigna") ? true : false )}*/}
                                {/*        onChange={this.handleChangeInsuranceCompanyFilter}*/}
                                {/*    />*/}
                                {/*    <div className="check-button check-button--checkbox">Cigna*/}
                                {/*        <div className="check-button__indicator">*/}
                                {/*            <span className="icon-check-mark"></span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</label>*/}

                            </div>
                        </Col>
                    </Row>

                </div>

                {(insurees_data)
                    ? <div className={`filterCard travelInfoCard ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>
                        <div className="filterCardTitle"><Trans i18nKey={"coveregePage_info_title"}/></div>

                        <Row>
                            <Col xs={12}>
                                <div className={`inputBoxResultPage ${(insurees_data.insuree_has_cfe) ? "is-valid-input" : "not-valid-input" }`}>
                                    <div className="inputBoxTitle">
                                        Couvert&middot;e par la CFE ?
                                        <Tooltip
                                            tooltipId="res_cfe"
                                            tooltipType="light"
                                            tooltipEffect="solid"
                                            tooltipIconSize={15}
                                            tooltipIconTopPosition={1}
                                            tooltipIconOffset={8}
                                            tooltipContent={i18n.t("mainPage_cfe_coverege_i")}
                                        />
                                    </div>

                                    <Select
                                        value={ (insurees_data) ? INSURANCE_CFE_OPTIONS.filter(option => option.value === insurees_data.insuree_has_cfe) : null }
                                        components={{ DropdownIndicator }}
                                        classNamePrefix="insurance-main-select"
                                        onChange={this.handleChangeInsureeHasCfe}
                                        options={INSURANCE_CFE_OPTIONS}
                                        isSearchable={false}
                                    />

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <div className="inputBoxResultPage">
                                    <div className="inputBoxTitle"><Trans i18nKey="coveregePage_info_insuree"></Trans></div>

                                    <Row className="insureesRowResultPage">

                                        <Col xs={6} className={(insurees_data.insuree_object.nationality) ? "is-valid-input" : "not-valid-input" }>
                                            <Select
                                                value={ (insurees_data) ? NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.insuree_object.nationality) : null }
                                                components={{ DropdownIndicator }}
                                                classNamePrefix="insurance-main-select"
                                                className="nat-select"
                                                placeholder={i18n.t("global_select")}
                                                onChange={this.handleChangeNationality}
                                                options={NATIONALITY_OPTIONS}
                                            />
                                        </Col>
                                        <Col xs={4} className={(insurees_data.insuree_object.age) ? "is-valid-input" : "not-valid-input" }>
                                            <Select
                                                value={ (insurees_data) ? AGE_OPTIONS.filter(option => option.value === insurees_data.insuree_object.age) : null }
                                                components={{ DropdownIndicator }}
                                                placeholder="0"
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeAge}
                                                options={AGE_OPTIONS}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <button className="addDependentsButton" onClick={this.handleAddDependentItem}>
                                                <span className="icon-user"></span> <Trans i18nKey="mainPage_form_add_person"></Trans>
                                            </button>
                                        </Col>

                                    </Row>

                                    <div className="dependentsContainer">

                                        { this.renderDependentsList() }

                                    </div>

                                </div>
                            </Col>
                        </Row>



                        <Row>
                            <Col xs={12}>
                                <div className={`inputBoxResultPage ${(insurees_data.destination_country) ? "is-valid-input" : "not-valid-input" }`}>
                                    <div className="inputBoxTitle"><Trans i18nKey="mainPage_form_country_emigration"></Trans></div>

                                    <Select
                                        value={ (insurees_data) ? DESTINATION_COUNTRY_OPTIONS.sort().filter(option => option.value === insurees_data.destination_country) : null }
                                        components={{ DropdownIndicator }}
                                        classNamePrefix="insurance-main-select"
                                        onChange={this.handleChangeDestinationCountry}
                                        options={DESTINATION_COUNTRY_OPTIONS.sort((prev, next) => {
                                            if ( prev.label < next.label ) return -1;
                                            if ( prev.label < next.label ) return 1;
                                        })}
                                    />

                                </div>
                            </Col>
                        </Row>

                        {/*<Row>*/}
                        {/*    <Col xs={12}>*/}
                        {/*        <div className={`inputBoxResultPage last-element ${(insurees_data.insurance_duration) ? "is-valid-input" : "not-valid-input" }`}>*/}
                        {/*            <div className="inputBoxTitle">Durée du séjour :</div>*/}

                        {/*            <Select*/}
                        {/*                value={ (insurees_data) ? INSURANCE_DURATION_OPTIONS.filter(option => option.value === insurees_data.insurance_duration) : null }*/}
                        {/*                components={{ DropdownIndicator }}*/}
                        {/*                classNamePrefix="insurance-main-select"*/}
                        {/*                onChange={this.handleChangeInsuranceDuration}*/}
                        {/*                options={INSURANCE_DURATION_OPTIONS}*/}
                        {/*                isSearchable={false}*/}
                        {/*            />*/}

                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        { (this.state.form_changes_detected) ?
                            <Row className="only-desktop">
                                <Col xs={12}>
                                    <button className="mainButtonStyle updateInsurancePlansButton" onClick={this.handleUpdateInsurancePlans}><Trans i18nKey="global_refresh"/></button>
                                </Col>
                            </Row>
                            : null }

                    </div>
                    : null }

            </>

        );


    }


    renderModalContent() {

        switch ( this.state.modal_content_type ) {

            case 'compare-plans':

                return (

                    <Modal
                        id={this.generateTableStyleIdForCompare()}
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        backdropClassName="compare-backdrop"
                        dialogClassName="full-window-modal">
                        <Modal.Header closeButton>
                            <Container className={`defaultContainer`+" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>
                                <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>
                                    <span className="icon-big-arrow-left"></span>
                                    <span className="close-modal-button-title"><Trans i18nKey={"getPlanePage_back_arrow"}/></span>
                                </button>
                                <Modal.Title><Trans i18nKey={"getPlanePage_plane_details_title"}/></Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <ComparesPlan />
                        </Modal.Body>
                    </Modal>

                );

            case 'view-plan':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="full-window-modal">
                        <Modal.Header closeButton>
                            <Container className={`defaultContainer`+" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>
                                <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>
                                    <span className="icon-big-arrow-left"></span>
                                    <span className="close-modal-button-title"><Trans i18nKey={"getPlanePage_back_arrow"}/></span>
                                </button>
                                <Modal.Title><Trans i18nKey={"getPlanePage_plane_details_title"}/></Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewPlan />
                        </Modal.Body>
                    </Modal>

                );



            case 'filters':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        // backdrop="static"
                        keyboard={false}
                        dialogClassName="full-window-modal left-align-window-modal filter-modal-window only-mobile">
                        <Modal.Header closeButton>

                            {/*<div className="mobileFilterHeader">*/}
                            <span className="mobileFilterHeaderTitle"><Trans i18nKey={"coveregePage_filter_title_mobile"}/></span>
                            <button className="mobileFilterCloseButton" onClick={this.handleCloseModal}><span className="icon-close-thin"></span></button>
                            {/*</div>*/}

                        </Modal.Header>
                        <Modal.Body>

                            { this.renderFiltersCardContent() }

                            { this.renderMobileFilterButton() }

                        </Modal.Body>
                    </Modal>

                );

            case 'gating-modal':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal gating-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                <Modal.Title>
                                    <Trans i18nKey={"resultPage_gating_modal_title"}/>
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="gatingSubTitle"><Trans i18nKey={"resultPage_gating_modal_subtitle"}/></div>
                            <div className="gatingIcon"><img src={gatingIcon} alt="expat assurance" /></div>
                            <div className="gatingDesc"><Trans i18nKey={"resultPage_gating_modal_desc"}/>?</div>

                            <div className="gatingInputs">
                                <input
                                    onKeyPress={(e) => { return (e.charCode === 13) ? this.handleGatingButton() : null }}
                                    className={ (this.state.gating_input_validation_error) ? 'gating-error animated input-error-bounce' : null }
                                    ref={this.gatingInput}
                                    type="email"
                                    placeholder="thibault.dupont@exemple.fr" />
                            </div>

                            <button
                                className="secondaryButtonStyle gatingFormButton"
                                onClick={this.handleGatingButton}>
                                <Trans i18nKey={"mainPage_form_submit_button"}/>
                            </button>

                        </Modal.Body>
                    </Modal>

                );

            case 'warning-message-expatriation-country':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    <Trans i18nKey={"global_warning_message_expatriation_country_modal_title"}/>
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <div> <Trans i18nKey={"global_warning_message_expatriation_country_modal_subtitle"}/></div>
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}><Trans i18nKey={"getPlanePage_criteries"}/></button>
                        </Modal.Body>
                    </Modal>

                );

            case 'warning-message-adults-amount':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    <Trans i18nKey={"global_warning_message_adults_amount_modal_title"}/>
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<div>Vous pouvez modifier votre choix pour accéder aux résultats.</div>*/}
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}><Trans i18nKey={"getPlanePage_criteries"}/></button>
                        </Modal.Body>
                    </Modal>

                );

            default:

                return null

        }

    }


    renderInsurancePlans() {

        let insurees_data = this.props.insurees_data;

        // перевірка чи юзер пройшов ворота наші
        const gate_closed = localStorage.getItem('gate_closed');

        if ( this.state.started_insurance_plans_array && gate_closed === 'false' ) {

            let sorted_plans_array_after_filtering;

            // let plans_array_after_filtering = this.filteringInsurancePlansArray();
            let plans_array_after_filtering = this.state.final_insurance_plans_array_after_sorting;

            if ( plans_array_after_filtering !== null ) {

                if (plans_array_after_filtering.length > 0 && this.state.hidden_insurance_plans_array_after_sorting !== plans_array_after_filtering.length) {

                    return plans_array_after_filtering.map((item_plan, index) => {

                        // перевірка на "недоступні плани"
                        if (item_plan.is_available !== "true") {

                            console.log("The plan " + item_plan.plan_id + " is not available because " + item_plan.not_available_reason);
                            return false;

                        }

                        const COINSURANCE_OPTIONS = this.generateFormattedCoinsuranceOptions(item_plan.coinsurance_options.values);
                        const DEDUCTIBLE_OPTIONS = this.generateFormattedDeductibleOptions(item_plan.deductible_options.values, insurees_data.destination_country, item_plan.insurance_company);


                        return (

                            <Row
                                className={`insurancePlanRow ${(this.state.maximized_plans_array.includes(item_plan.plan_id)) ? 'is-maximized' : null}
                                ${
                                    ((item_plan.insurance_company !== "BUPA International" && item_plan.insurance_company !== "Bupa Nacional Vital" && item_plan.insurance_company !== "Palig") && this.props.currency === "MXN") ?
                                        "hide" : ""
                                }

                                ${
                                    (item_plan.insurance_company === "BUPA International" && this.props.currency !== "MXN") ?
                                        "hide" : ""
                                }

                                ${
                                    (item_plan.insurance_company === "Bupa Nacional Vital" && this.props.currency !== "MXN") ?
                                        "hide" : ""
                                }

                                ${
                                    (item_plan.insurance_company === "Palig" && this.props.currency !== "MXN") ?
                                        "hide" : ""
                                }

                                `}
                                key={item_plan.plan_id}>

                                <Col className={`insurancePlanItem ${item_plan.plan_id}`}>

                                    <Row className="minimizedPlanVersion">

                                        <Col xs={12}>

                                            <Row className="insurancePlanFirstRow">
                                                <Col className="miniPlanLogoSection" xs={{span: 2, order: 1}}>
                                                    <span></span>
                                                    <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)}
                                                         alt={item_plan.insurance_company}/>
                                                </Col>

                                                <Col className="miniPlanTypeOfCoverageSection" xs={{span: 5, order: 2}}>
                                                    {renderTypeOfCoverageList(item_plan.coverage_types_included, item_plan.insurance_company, item_plan.plan_id)}
                                                </Col>

                                                <Col className="miniPlanPriceSection" xs={{span: 5, order: 2}}>
                                                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                                                    <div className="planPriceValue">

                                                    <span data-for={`usd_price_${item_plan.plan_id}`}
                                                          data-tip="Le prix de cette offre est exprimé en dollars américains">
                                                        {
                                                            generateFormattedPrice(item_plan.currentPrice, this.props.currency)
                                                        }

                                                        {(insurees_data.destination_country === 'États-Unis d’Amérique')
                                                            ?
                                                            <Tooltip
                                                                tooltipId={`usd_price_${item_plan.plan_id}`}
                                                                tooltipType="light"
                                                                tooltipEffect="float"
                                                            />
                                                            : null}

                                                    </span>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col xs={12} className="planButtonsSection">

                                            <div className="planButtonsRow">

                                                <button
                                                    className="secondaryButtonStyle viewPlanButton"
                                                    onClick={this.handleShowMaximizedPlanVersion.bind(this, item_plan.plan_id)}>
                                                    <Trans i18nKey={"resultPage_show_maximized_plan_version"}/> <span
                                                    className="icon-arrow-down"></span></button>

                                                {(this.props.compare_plans_local_array.length === 3 && !this.props.compare_plans_local_array.filter(option => option.plan_id === item_plan.plan_id).length)

                                                    ?

                                                    <>

                                                        <button
                                                            className="check-button-container compare-check-button"
                                                            data-for={`compare_btn_${item_plan.plan_id}`}
                                                            data-tip={i18n.t("resultPage_compare_max_3")}>

                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="compare-checkbox"
                                                                    defaultChecked={false}
                                                                    readOnly={true}
                                                                    disabled={true}
                                                                />
                                                                <span className="check-button--checkbox not-allowed">
                                                                    <span><Trans i18nKey={"resultPage_compare_button"}/></span>
                                                                    <span
                                                                        className="compare-button-icon icon-compare"></span>
                                                                    <span
                                                                        className="compare-button-icon icon-trash"></span>
                                                                </span>
                                                            </label>

                                                        </button>

                                                        <Tooltip
                                                            tooltipId={`compare_btn_${item_plan.plan_id}`}
                                                            tooltipType="light"
                                                            tooltipEffect="float"
                                                            warningMode={true}
                                                        />

                                                    </>

                                                    :

                                                    <button className="check-button-container compare-check-button">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="compare-checkbox"
                                                                checked={(this.props.compare_plans_local_array.filter(option => option.plan_id === item_plan.plan_id).length) ? true : false}
                                                                onChange={this.handleComparePlan.bind(this, item_plan.plan_id)}
                                                            />
                                                            <span className="check-button--checkbox">
                                                                <span><Trans
                                                                    i18nKey={"resultPage_compare_button"}/></span>
                                                                <span
                                                                    className="compare-button-icon icon-compare"></span>
                                                                <span className="compare-button-icon icon-trash"></span>
                                                            </span>
                                                        </label>
                                                    </button>

                                                }


                                            </div>


                                            {/* TODO: це для тестування, потім видалити */}
                                            <div className="test_id_block"
                                                 style={{color: "transparent"}}>{item_plan.plan_id}</div>

                                        </Col>


                                    </Row>

                                    <Row className="maximizedPlanVersion">

                                        <div className="maximizedPlanVersionClose"
                                             onClick={this.handleShowMinimizedPlanVersion.bind(this, item_plan.plan_id)}>
                                            <span className="icon-close"></span></div>

                                        <Col xs={{span: 12, order: 1}} md={{span: 2, order: 1}}
                                             className="planLogoSection">

                                            <Row className="insurancePlanFirstRow">
                                                <Col className="insurancePlanLogoBlock" xs={{span: 6, order: 1}}
                                                     md={{span: 12, order: 2}}>
                                                    <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)}
                                                         alt={item_plan.insurance_company}/>
                                                </Col>

                                                <Col className="insurancePlanTagBlock" xs={{span: 6, order: 2}}
                                                     md={{span: 12, order: 1}}>
                                                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col xs={{span: 12, order: 3}} md={{span: 2, order: 2}}
                                             className="planTypeOfCoverageSection">

                                            {renderTypeOfCoverageList(item_plan.coverage_types_included, item_plan.insurance_company, item_plan.plan_id)}

                                        </Col>
                                        <Col xs={{span: 12, order: 2}} md={{span: 5, order: 3}}
                                             className="planPriceSection">

                                            <div className="planOptionsSectionBody">

                                                <Row className="planPriceBlock">

                                                    <Col
                                                        className="planPriceTitle">
                                                        {
                                                            (process.env.REACT_APP_LANGUAGE === "EN")
                                                                ? <>{(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label}
                                                                    <Trans i18nKey="global_price_text"/></>
                                                                : <><Trans
                                                                    i18nKey="global_price_text"/> {(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label.toLowerCase()}</>
                                                        }
                                                    </Col>

                                                    <Col className="planPriceValue">

                                                    <span data-for={`usd_price_${item_plan.plan_id}`}
                                                          data-tip={i18n.t("resultPage_usd_price_tip")}>

                                                        {
                                                            generateFormattedPrice(item_plan.currentPrice, this.props.currency)
                                                        }

                                                        {(detectCurrency(insurees_data.destination_country))
                                                            ?
                                                            <Tooltip
                                                                tooltipId={`usd_price_${item_plan.plan_id}`}
                                                                tooltipType="light"
                                                                tooltipEffect="float"
                                                            />
                                                            : null}

                                                    </span>

                                                    </Col>

                                                </Row>

                                                <Row className="planOptionsBlock">

                                                    <Col
                                                        xs={12}
                                                        data-for={`res_opt_coins_${item_plan.plan_id}`}
                                                        data-tip={item_plan.coinsurance_options.locked_reason}
                                                        className={`planOptionItem planOptionItemCoinsurance ${(item_plan.coinsurance_options.type === "LOCKED") ? "is-disabled" : null} ${(item_plan.selected_radio_option === "coinsurance") ? 'is-active' : 'not-active'}`}
                                                        onClick={(item_plan.coinsurance_options.type !== "LOCKED") ? this.handleChangeActivePriceOptionsType.bind(this, 'coinsurance', item_plan.plan_id) : null}
                                                    >
                                                        <Row>
                                                            <Col xs={8} className="planOptionItemRadioInput">
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id={`plan_option_coinsurance_${item_plan.plan_id}`}
                                                                        value={`plan_option_coinsurance_${item_plan.plan_id}`}
                                                                        name={`plan_option_radio_${item_plan.plan_id}`}
                                                                        checked={(item_plan.selected_radio_option === "coinsurance") ? true : false}
                                                                        // disabled={(item_plan.coinsurance_options.type === "LOCKED") ? true : false}
                                                                        readOnly={true}
                                                                        disabled={true}
                                                                    />
                                                                    <label
                                                                        htmlFor={`plan_option_coinsurance_${item_plan.plan_id}`}>
                                                                        <Trans
                                                                            i18nKey="coveregePage_item_healthcare"></Trans>

                                                                        {/* Tooltip for LOCKED coinsurance option */}
                                                                        {(item_plan.coinsurance_options.type === "LOCKED" &&
                                                                            item_plan.insurance_company != 'April' &&
                                                                            item_plan.insurance_company != 'Allianz'
                                                                        ) ?
                                                                            <Tooltip
                                                                                tooltipId={`res_opt_coins_${item_plan.plan_id}`}
                                                                                tooltipType="light"
                                                                                tooltipEffect="solid"
                                                                                tooltipIconSize={13}
                                                                                tooltipIconTopPosition={1}
                                                                                tooltipIconOffset={5}
                                                                            />
                                                                            : null}

                                                                        {(item_plan.insurance_company === 'April')
                                                                            ?
                                                                            <Tooltip
                                                                                tooltipId={`res_coins${item_plan.plan_id}`}
                                                                                tooltipType="light"
                                                                                tooltipEffect="solid"
                                                                                tooltipIconSize={13}
                                                                                tooltipIconTopPosition={1}
                                                                                tooltipIconOffset={5}
                                                                                tooltipContent={i18n.t("resultPage_healthcare_tooltip_content")}
                                                                            />
                                                                            : null}

                                                                        {(item_plan.insurance_company === 'Allianz')
                                                                            ?
                                                                            <Tooltip
                                                                                tooltipId={`res_coins${item_plan.plan_id}`}
                                                                                tooltipType="light"
                                                                                tooltipEffect="solid"
                                                                                tooltipIconSize={13}
                                                                                tooltipIconTopPosition={1}
                                                                                tooltipIconOffset={5}
                                                                                tooltipContent={i18n.t("resultPage_alianz_tooltip_content")}
                                                                            />
                                                                            : null}

                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xs={4} className="planOptionItemSelectInput">
                                                                <Select
                                                                    value={(item_plan.coinsurance_selected_object) ? COINSURANCE_OPTIONS.filter(option => option.value === item_plan.coinsurance_selected_object.value) : null}
                                                                    components={{DropdownIndicator}}
                                                                    isDisabled={(item_plan.coinsurance_options.type === "LOCKED") ? true : false}
                                                                    placeholder={((item_plan.plan_id === "BN1.2") || (item_plan.plan_id === "BN1.3") || (item_plan.plan_id === "BN2.2") || (item_plan.plan_id === "BN2.3")) ? "90%" : "100%"}
                                                                    className="plan-price-option"
                                                                    classNamePrefix="insurance-main-select"
                                                                    onChange={this.handleChangeCoinsuranceOption.bind(this, item_plan.plan_id)}
                                                                    options={COINSURANCE_OPTIONS}
                                                                    htmlFor={`coinsurance_${item_plan.plan_id}`}
                                                                    isSearchable={false}
                                                                />
                                                            </Col>
                                                        </Row>


                                                    </Col>

                                                    <Col
                                                        xs={12}
                                                        data-for={`res_opt_deduct_${item_plan.plan_id}`}
                                                        data-tip={item_plan.deductible_options.locked_reason}
                                                        className={`planOptionItem planOptionItemDeductible ${(item_plan.insurance_company === "Bupa Nacional Vital") ? "is-disabled" : (item_plan.deductible_options.type === "LOCKED") ? "is-disabled" : null} ${(item_plan.selected_radio_option === "deductible") ? 'is-active' : 'not-active'}`}
                                                        onClick={(item_plan.deductible_options.type !== "LOCKED") ? this.handleChangeActivePriceOptionsType.bind(this, 'deductible', item_plan.plan_id) : null}
                                                    >
                                                        <Row>
                                                            <Col xs={8} className="planOptionItemRadioInput">
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id={`plan_option_deductible_${item_plan.plan_id}`}
                                                                        value={`plan_option_deductible_${item_plan.plan_id}`}
                                                                        name={`plan_option_radio_${item_plan.plan_id}`}
                                                                        checked={(item_plan.selected_radio_option === "deductible") ? true : false}
                                                                        // disabled={(item_plan.deductible_options.type === "LOCKED") ? true : false}
                                                                        readOnly={true}
                                                                        disabled={true}
                                                                    />
                                                                    <label
                                                                        htmlFor={`plan_option_deductible_${item_plan.plan_id}`}><Trans
                                                                        i18nKey="coveregePage_item_franchise"></Trans>

                                                                        {/* Tooltip for LOCKED deductible option */}
                                                                        {(item_plan.deductible_options.type === "LOCKED") ?
                                                                            <Tooltip
                                                                                tooltipId={`res_opt_deduct_${item_plan.plan_id}`}
                                                                                tooltipType="light"
                                                                                tooltipEffect="solid"
                                                                                tooltipIconSize={13}
                                                                                tooltipIconTopPosition={1}
                                                                                tooltipIconOffset={5}
                                                                            />
                                                                            : null}

                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xs={4} className="planOptionItemSelectInput">
                                                                <Select
                                                                    value={(item_plan.deductible_selected_object) ? DEDUCTIBLE_OPTIONS.filter(option => option.value === item_plan.deductible_selected_object.value) : null}
                                                                    components={{DropdownIndicator}}
                                                                    isDisabled={(item_plan.deductible_options.type === "LOCKED") ? true : false}
                                                                    placeholder={item_plan.insurance_company === "BUPA International" || item_plan.insurance_company === "Bupa Nacional Vital" || item_plan.insurance_company === "Palig" ?
                                                                        (item_plan.deductible_options ? item_plan.deductible_options.values[0] + "$" : "") : "0€"}
                                                                    className="plan-price-option"
                                                                    classNamePrefix="insurance-main-select"
                                                                    onChange={(selectedOption) => {
                                                                        this.handleChangeDeductibleOption(item_plan.plan_id, selectedOption);
                                                                    }}
                                                                    options={DEDUCTIBLE_OPTIONS}
                                                                    htmlFor={`deductible${item_plan.plan_id}`}
                                                                    isSearchable={false}
                                                                />

                                                            </Col>
                                                        </Row>


                                                    </Col>


                                                </Row>


                                            </div>

                                        </Col>
                                        <Col xs={{span: 12, order: 4}} md={{span: 3, order: 4}}
                                             className="planButtonsSection">

                                            <div className="planButtonsRow">

                                                <button
                                                    className="mainButtonStyle getPlanButton"
                                                    onClick={this.handleGetPlan.bind(this, item_plan.plan_id)}><Trans
                                                    i18nKey={"resultPage_get_plan_button"}/>
                                                </button>

                                            </div>

                                            <div className="planButtonsRow">

                                                <button
                                                    className="secondaryButtonStyle viewPlanButton viewPlanButtonLinkLike"
                                                    onClick={this.handleViewPlan.bind(this, item_plan.plan_id)}><Trans
                                                    i18nKey={"coveregePage_details"}/>
                                                </button>

                                                {(this.props.compare_plans_local_array.length === 3 && !this.props.compare_plans_local_array.filter(option => option.plan_id === item_plan.plan_id).length)

                                                    ?

                                                    <>

                                                        <button
                                                            className="check-button-container compare-check-button"
                                                            data-for={`compare_btn_${item_plan.plan_id}`}
                                                            data-tip={i18n.t("resultPage_compare_max_3")}>

                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="compare-checkbox"
                                                                    defaultChecked={false}
                                                                    readOnly={true}
                                                                    disabled={true}
                                                                />
                                                                <span className="check-button--checkbox not-allowed">
                                                                    <span><Trans i18nKey={"resultPage_compare_button"}/></span>
                                                                    <span
                                                                        className="compare-button-icon icon-compare"></span>
                                                                    <span
                                                                        className="compare-button-icon icon-trash"></span>
                                                                </span>
                                                            </label>

                                                        </button>

                                                        <Tooltip
                                                            tooltipId={`compare_btn_${item_plan.plan_id}`}
                                                            tooltipType="light"
                                                            tooltipEffect="float"
                                                            warningMode={true}
                                                        />

                                                    </>

                                                    :

                                                    <button className="check-button-container compare-check-button">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="compare-checkbox"
                                                                checked={(this.props.compare_plans_local_array.filter(option => option.plan_id === item_plan.plan_id).length) ? true : false}
                                                                onChange={this.handleComparePlan.bind(this, item_plan.plan_id)}
                                                            />
                                                            <span className="check-button--checkbox">
                                                                <span><Trans
                                                                    i18nKey={"resultPage_compare_button"}/></span>
                                                                <span
                                                                    className="compare-button-icon icon-compare"></span>
                                                                <span className="compare-button-icon icon-trash"></span>
                                                            </span>
                                                        </label>
                                                    </button>

                                                }


                                            </div>


                                            {/* TODO: це для тестування, потім видалити */}
                                            <div className="test_id_block"
                                                 style={{color: "transparent"}}>{item_plan.plan_id}</div>

                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                        )

                    });


                } else {

                    return (

                        <Row>
                            <div className="plansIsNotFound">
                                <span className="icon-search"></span>
                                <div className="plansIsNotFoundTitle">
                                    <Trans i18nKey="global_no_plans_found"/>
                                </div>
                            </div>
                        </Row>

                    )

                }

            }

        } else {

            return (

                <>

                    <Row className="insurancePlanRow insurancePlanRowLoader">

                        <Col className="insurancePlanItem">

                            <Row>

                                <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 1 }} className="planLogoSection">

                                    <Row className="insurancePlanFirstRow">
                                        <Col className="insurancePlanLogoBlock" xs={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }}>
                                            <div className="insurancePlanLogoLoader load-content-animation"></div>
                                        </Col>

                                        <Col className="insurancePlanTagBlock" xs={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                                            <div className="planGuaranteeLevelName load-content-animation"></div>
                                        </Col>

                                    </Row>

                                </Col>
                                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 2 }} className="planTypeOfCoverageSection">

                                    <ul>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                    </ul>

                                </Col>
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 3 }} className="planPriceSection">

                                    <div className="planOptionsSectionBody load-content-animation">

                                        <Row className="planOptionsBlock"></Row>

                                    </div>

                                </Col>
                                <Col xs={{ span: 12, order: 4 }} md={{ span: 3, order: 4 }} className="planButtonsSection">

                                    <div className="planButtonsRow">
                                        <button className="mainButtonStyle getPlanButton load-content-animation"></button>
                                    </div>

                                    <div className="planButtonsRow">

                                        <button className="secondaryButtonStyle viewPlanButton load-content-animation"></button>

                                        <button className="check-button-container compare-check-button">

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="compare-checkbox"
                                                    defaultChecked={false}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                                <span className="check-button--checkbox not-allowed load-content-animation">
                                                    <span></span>
                                                </span>
                                            </label>

                                        </button>

                                    </div>

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                    <Row className="insurancePlanRow insurancePlanRowLoader">

                        <Col className="insurancePlanItem">

                            <Row>

                                <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 1 }} className="planLogoSection">

                                    <Row className="insurancePlanFirstRow">
                                        <Col className="insurancePlanLogoBlock" xs={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }}>
                                            <div className="insurancePlanLogoLoader load-content-animation"></div>
                                        </Col>

                                        <Col className="insurancePlanTagBlock" xs={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                                            <div className="planGuaranteeLevelName load-content-animation"></div>
                                        </Col>

                                    </Row>

                                </Col>
                                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 2 }} className="planTypeOfCoverageSection">

                                    <ul>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                    </ul>

                                </Col>
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 3 }} className="planPriceSection">

                                    <div className="planOptionsSectionBody load-content-animation">

                                        <Row className="planOptionsBlock"></Row>

                                    </div>

                                </Col>
                                <Col xs={{ span: 12, order: 4 }} md={{ span: 3, order: 4 }} className="planButtonsSection">

                                    <div className="planButtonsRow">
                                        <button className="mainButtonStyle getPlanButton load-content-animation"></button>
                                    </div>

                                    <div className="planButtonsRow">

                                        <button className="secondaryButtonStyle viewPlanButton load-content-animation"></button>

                                        <button className="check-button-container compare-check-button">

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="compare-checkbox"
                                                    defaultChecked={false}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                                <span className="check-button--checkbox not-allowed load-content-animation">
                                                <span></span>
                                            </span>
                                            </label>

                                        </button>

                                    </div>

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                    <Row className="insurancePlanRow insurancePlanRowLoader">

                        <Col className="insurancePlanItem">

                            <Row>

                                <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 1 }} className="planLogoSection">

                                    <Row className="insurancePlanFirstRow">
                                        <Col className="insurancePlanLogoBlock" xs={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }}>
                                            <div className="insurancePlanLogoLoader load-content-animation"></div>
                                        </Col>

                                        <Col className="insurancePlanTagBlock" xs={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                                            <div className="planGuaranteeLevelName load-content-animation"></div>
                                        </Col>

                                    </Row>

                                </Col>
                                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 2 }} className="planTypeOfCoverageSection">

                                    <ul>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                    </ul>

                                </Col>
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 3 }} className="planPriceSection">

                                    <div className="planOptionsSectionBody load-content-animation">

                                        <Row className="planOptionsBlock"></Row>

                                    </div>

                                </Col>
                                <Col xs={{ span: 12, order: 4 }} md={{ span: 3, order: 4 }} className="planButtonsSection">

                                    <div className="planButtonsRow">
                                        <button className="mainButtonStyle getPlanButton load-content-animation"></button>
                                    </div>

                                    <div className="planButtonsRow">

                                        <button className="secondaryButtonStyle viewPlanButton load-content-animation"></button>

                                        <button className="check-button-container compare-check-button">

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="compare-checkbox"
                                                    defaultChecked={false}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                                <span className="check-button--checkbox not-allowed load-content-animation">
                                                <span></span>
                                            </span>
                                            </label>

                                        </button>

                                    </div>

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                    <Row className="insurancePlanRow insurancePlanRowLoader">

                        <Col className="insurancePlanItem">

                            <Row>

                                <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 1 }} className="planLogoSection">

                                    <Row className="insurancePlanFirstRow">
                                        <Col className="insurancePlanLogoBlock" xs={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }}>
                                            <div className="insurancePlanLogoLoader load-content-animation"></div>
                                        </Col>

                                        <Col className="insurancePlanTagBlock" xs={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                                            <div className="planGuaranteeLevelName load-content-animation"></div>
                                        </Col>

                                    </Row>

                                </Col>
                                <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 2 }} className="planTypeOfCoverageSection">

                                    <ul>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                        <li className="load-content-animation"></li>
                                    </ul>

                                </Col>
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 3 }} className="planPriceSection">

                                    <div className="planOptionsSectionBody load-content-animation">

                                        <Row className="planOptionsBlock"></Row>

                                    </div>

                                </Col>
                                <Col xs={{ span: 12, order: 4 }} md={{ span: 3, order: 4 }} className="planButtonsSection">

                                    <div className="planButtonsRow">
                                        <button className="mainButtonStyle getPlanButton load-content-animation"></button>
                                    </div>

                                    <div className="planButtonsRow">

                                        <button className="secondaryButtonStyle viewPlanButton load-content-animation"></button>

                                        <button className="check-button-container compare-check-button">

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="compare-checkbox"
                                                    defaultChecked={false}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                                <span className="check-button--checkbox not-allowed load-content-animation">
                                                <span></span>
                                            </span>
                                            </label>

                                        </button>

                                    </div>

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </>

            );

        }

    }



    render() {

        // console.log(this.state);
        // console.log(this.props);
        // console.log(this.state.started_insurance_plans_array);


        // let query = new URLSearchParams(this.props.location.search);
        // console.log(query.get("view-plan"));

        const gate_closed = localStorage.getItem('gate_closed');

        return (

            <div className="resultPage">

                {/*TODO: це для майбутньої модалки*/}
                {/*<button onClick={this.generateUrlWithMainFormData.bind()}>Opa opa</button>*/}

                {/* Фіксована кнопка фільтрів, тільки для мобільної версії */}
                <Row>
                    <button
                        className="mobileFilterButton only-mobile"
                        onClick={this.handleShowModal.bind(this, 'filters')}><span className="icon-filter"></span> <Trans i18nKey={"coveregePage_filter_title_mobile"}/></button>
                </Row>


                <Container className="defaultContainer resultPageContainer">

                    <Row>

                        <Col className="resultPageLeftPartCol" xs={3}>

                            { this.renderFiltersCardContent() }

                        </Col>

                        <Col className="resultPageRightPartCol" xs={9}>

                            <div className="resultPageRightPart">

                                <Row className="insurancePlansSortAndOptionsContainer">

                                    <Col className="" xs={12}>
                                        <div className="insurancePlansAmountBlock">
                                            {/* TODO: не дуже оптимізовано дьоргати цілу функцію фільотрування щоб дістати amount */}
                                            {(( this.state.started_insurance_plans_array && gate_closed === 'false' )
                                                    ? <> <span>
                                                {((this.state.final_insurance_plans_array_after_sorting)
                                                    ? this.state.final_insurance_plans_array_after_sorting.length - this.state.hidden_insurance_plans_array_after_sorting
                                                    : 0 )} <Trans i18nKey="resultPage_compare_modal_title_plans"></Trans>
                                            </span> <Trans i18nKey="coveregePage_plans_count"></Trans> </>
                                                    : <><Trans i18nKey="resultPage_loading_title"></Trans></>
                                            )}
                                            <p>
                                                <Trans i18nKey="resultPage_under_loading_title1"></Trans><br />
                                                <Trans i18nKey="resultPage_under_loading_title2"></Trans>
                                            </p>
                                        </div>
                                    </Col>
                                    {/*<Col className="warningBlock" xs={12}>*/}
                                    {/*    <Row>*/}
                                    {/*        <div className="warningBlockImage col-1">*/}
                                    {/*            <div className="warningBlockImageBox">*/}
                                    {/*                <img src={warning}/>*/}
                                    {/*                <p>Attention</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="warningBlockText col-11">*/}
                                    {/*            <Trans i18nKey={"warning-text"} components={{span : <span/>}}/>*/}
                                    {/*        </div>*/}
                                    {/*    </Row>*/}
                                    {/*</Col>*/}
                                    <div className="plans-filters-box">
                                        <div className="">
                                            <div className="paymentFrequencyBlock sortBlock">
                                                <label><span className="icon-sort"></span> <Trans i18nKey="coveregePage_payment_currency"></Trans></label>
                                                <Select
                                                    value={ (this.props.sort) ? SORT_OPTIONS.filter(option => option.value === this.props.sort) : null }
                                                    components={{ DropdownIndicator }}
                                                    classNamePrefix="insurance-main-select"
                                                    className="payment-frequency-option"
                                                    onChange={this.handleChangeSortingOption}
                                                    options={SORT_OPTIONS}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="paymentFrequencyBlock paymentCurrencyBlock">
                                                <label><span className="icon-curruncy"></span></label>
                                                <Select
                                                    value={ (this.props.currency) ? PAYMENT_CURRENCY_OPTIONS.filter(option => option.value === this.props.currency) : null }
                                                    components={{ DropdownIndicator }}
                                                    classNamePrefix="insurance-main-select"
                                                    className="payment-frequency-option"
                                                    onChange={this.handleChangePaymentCurrencyOption}
                                                    options={PAYMENT_CURRENCY_OPTIONS}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="paymentFrequencyBlock">
                                                <label><span className="icon-payment-frequency"></span> <Trans i18nKey="coveregePage_payment_type_title"></Trans></label>
                                                <Select
                                                    value={ (this.props.payment_frequency_value) ? PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value) : null }
                                                    components={{ DropdownIndicator }}
                                                    classNamePrefix="insurance-main-select"
                                                    className="payment-frequency-option"
                                                    onChange={this.handleChangePaymentFrequencyOption}
                                                    options={PAYMENT_FREQUENCY_OPTIONS}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </Row>

                                <div className="insurancePlansContainer">

                                    { this.renderInsurancePlans() }

                                </div>

                            </div>

                        </Col>

                    </Row>

                </Container>


                {/* Compare Plan Fixed Modal */}
                <div className={`compareFixedModalBlock ${(this.props.compare_plans_local_array.length > 0) ? 'is-visible' : null}`}>
                    <div className="compareFixedModalTitle only-desktop"><Trans i18nKey={"resultPage_compare_modal_title"}/> <strong>{this.props.compare_plans_local_array.length} <Trans i18nKey={"resultPage_compare_modal_title_plans"}/></strong> <Trans i18nKey={"resultPage_compare_modal_title_end"}/></div>
                    <div className="compareFixedModalTitle only-mobile"><strong>{this.props.compare_plans_local_array.length} <Trans i18nKey={"resultPage_compare_modal_title_plans"}/></strong> <Trans i18nKey={"resultPage_compare_modal_title_end"}/></div>
                    <div className="compareFixedModalButtons">
                        <button className="comparePlansButton" onClick={this.handleShowModal.bind(this, 'compare-plans')}><Trans i18nKey={"resultPage_compare_button"}/></button>
                        <button className="uncheckAllPlansButton" onClick={this.handleUncheckAllComparePlans}><Trans i18nKey={"resultPage_compare_uncheck_all_button"}/> <span className="icon-close"></span></button>
                    </div>
                </div>


                {/*  MODALS PART  */}
                { this.renderModalContent() }


            </div>

        )

    }


}

export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        started_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        get_plan_data: state.get_plan_data.plan_data,
        view_plan_data: state.view_plan_data.local_plan_data,
        compare_plans_local_array: state.compare_plans_data.local_plans_data,
        payment_frequency_value: state.payment_frequency.payment_frequency_value,
        filters_options_object: state.filters_options.filters_options_object,
        gating_response: state.app_options.gating_response,
        currency: state.currency_options.currency,
        has_the_curency_value_changed : state.currency_options.availability,
        server_timeout: state.app_options.server_timeout,
        sort: state.filters_options.filters_options_object.sort
    }),
    dispatch => ({
        onFetchInsurancePlans: (main_form_data_object) => {
            dispatch(fetchInsurancePlans(main_form_data_object));
        },
        onSelectInsurancePlanDataObject: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForGetPlan(plan_data_object));
        },
        onSaveInsurancePlanDataForViewPlan: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForViewPlan(plan_data_object));
        },
        onSaveDataFromMainPageForm: (main_form_data_object) => {
            dispatch(saveDataFromMainPageForm(main_form_data_object));
        },
        onClearInsurancePlansArrayBeforeFetching: () => {
            dispatch(clearInsurancePlansArrayBeforeFetching());
        },
        onChangePaymentFrequencyValue: (new_payment_frequency_value) => {
            dispatch(changePaymentFrequencyValue(new_payment_frequency_value));
        },
        onClearInsurancePlanDataForViewPlan: () => {
            dispatch(clearInsurancePlanDataForViewPlan());
        },
        onChangeFilterOption: (filter_type_name, selected_option_check_value, selected_option_name) => {

            let new_filter_option_object = {
                filter_type_name: filter_type_name,
                selected_option_check_value: selected_option_check_value,
                selected_option_name: selected_option_name
            };

            dispatch(changeFilterOption(new_filter_option_object));
        },
        onSaveInsurancePlansDataForComparePlans: (plans_data_object) => {
            dispatch(saveInsurancePlansDataForComparePlans(plans_data_object));
        },
        onSendGatingDataToEmail: (data_for_sending) => {

            dispatch(sendGatingDataToEmail(data_for_sending));
        },
        onChangeCurrency: (currency) => {

            dispatch(changeCurrency(currency));
        },

        onChangeCurrencyChangeValue: (availability) => {

            dispatch(changeCurrencyChangeValue(availability));
        },

        onChangeSorting: (value) => {

            dispatch(changeSorting(value));
        },


    })
)(ResultPage));
