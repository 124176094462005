import React, { Component } from 'react';
import './ViewPlan.css';
import {Col, Container, Row, Spinner, Table} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    calculateFinalPrice,
    renderGuaranteeLevelNameTag,
    renderInsuranceCompanyLogo,
    renderTypeOfCoverageList,
    generateFormattedPrice
} from "../../../global-app-elements/global_helper_functions";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { PAYMENT_FREQUENCY_OPTIONS } from "../../../global-app-elements/global_selects_options";
import Select, {components} from "react-select";
import {
    saveInsurancePlanDataForGetPlan,
    changePaymentFrequencyValue,
    fetchInsurancePlanExtendedData
} from "../../../actions/actions";
import {Trans} from "react-i18next";





const IF_VIEW_PLAN_LEVEL_IS_NON = (<span className="icon-close"></span>);


const cloneDeep = require('clone-deep');
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};





class ViewPlan extends Component {


    constructor(props) {

        super(props);

        this.state = {
            data_type_for_USA_MSH: 'DANS'
        };

        this.handleGetPlan = this.handleGetPlan.bind(this);
        this.renderViewPlanAccordionItem = this.renderViewPlanAccordionItem.bind(this);
        this.renderViewPlanLevelValues = this.renderViewPlanLevelValues.bind(this);
        this.handleChangePaymentFrequencyOption = this.handleChangePaymentFrequencyOption.bind(this);
        this.handleChangeDataTypeForUsaMsh = this.handleChangeDataTypeForUsaMsh.bind(this);
        this.renderLevelValueTableCol = this.renderLevelValueTableCol.bind(this);
    }



    componentDidMount() {

        this.getInsurancePlanExtendedData();

    }


    componentDidUpdate(prevProps, prevState) {

        // Якщо користувач змінив datatype_for_USA_MSH , то треба оновити view plan дані
        if (prevState.data_type_for_USA_MSH !== this.state.data_type_for_USA_MSH) {

            this.getInsurancePlanExtendedData();

        }

    }


    handleGetPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.props.result_insurance_plans_array);

        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        this.props.onSelectInsurancePlanDataObject(current_plan_array[0]);

    }


    handleChangePaymentFrequencyOption(selected_option)  {

        this.props.onChangePaymentFrequencyValue(selected_option.value)

    }


    handleChangeDataTypeForUsaMsh(selected_option) {

        let input_value = selected_option.target.value;

        this.setState({ data_type_for_USA_MSH: input_value });

    }


    renderLevelValueTableCol(level_values_array, current_level_key, current_level_value) {

        // дізнаюсь index поточного левела який відмальовуєтся в даний момент
        const getCurrentLevelIndex = function(arr, current_level_key) {

            for (let level_index = 0; level_index < arr.length; level_index += 1) {

                if (arr[level_index]['level_key'] === current_level_key) {

                    return level_index;

                }

            }

        }


        // разхуємо кількість MERGE прапорців під ряд
        const getMergeFlagsAmount = function(arr, current_level_key) {

            let current_level_index = getCurrentLevelIndex(arr, current_level_key);
            let merge_flag_count = 1;

            for (let i = current_level_index + 1; i < arr.length; i++) {

                if (arr[i].level_value === "MERGE") {

                    merge_flag_count++;

                } else {

                    return merge_flag_count;

                }

            }

            return merge_flag_count;

        }


        // перевіряємо чи наступний лемент від поточного не MERGE
        const getNextLevelValue = function(arr, key) {

            for (let i = 0; i < arr.length; i++) {

                if (arr[i].level_key === key) {

                    return arr[i + 1] && arr[i + 1].level_value;

                }

            }

        };



        let next_level_value = getNextLevelValue(level_values_array, current_level_key);

        if ( current_level_value  !== "MERGE" ) {

            if ( next_level_value === 'MERGE' ) {


                let merge_flags_amount = getMergeFlagsAmount(level_values_array, current_level_key);


                if ( current_level_value === "NON" ) {

                    return (<td rowSpan={merge_flags_amount} className="non-value">{ IF_VIEW_PLAN_LEVEL_IS_NON }</td>);

                } else {

                    return (<td rowSpan={merge_flags_amount}>{ current_level_value }</td>  );

                }

            } else {

                if ( current_level_value === "NON" ) {

                    return (<td className="non-value">{ IF_VIEW_PLAN_LEVEL_IS_NON }</td>);

                } else {

                    return (<td >{ current_level_value }</td>  );

                }

            }

        }


    }


    renderViewPlanLevelValues(level_values_object) {

        let current_levels_values_array = [];

        // перетворю об*єкт з бекенду в масив , щоб можна було по ньому зручніше бігати
        for (let level_key in level_values_object) {

            current_levels_values_array.push({
                level_key: level_key,
                level_value: level_values_object[level_key]
            })

        }

        return current_levels_values_array.map((item, index) => {

            return (

                <tr key={index}>
                    <td>{item.level_key}</td>
                    {this.renderLevelValueTableCol(current_levels_values_array, item.level_key, item.level_value)}
                </tr>

            )

        });

    }


    renderViewPlanAccordionItem(coverage_levels) {

        let coverage_types_included = this.props.local_view_plan_data.coverage_types_included;

        return coverage_levels.map((item, index) => {

            if ( coverage_types_included.includes(item.coverage_level_to_match) ) {

                return (

                    <AccordionItem key={index} className="viewPlanDataGroupItem" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item.level_name}
                                <div className="accordion-indicator">
                                    <span className="accordion-indicator-title"><Trans i18nKey={"viewPlans_show_less"}/></span>
                                    <span className="accordion-indicator-icon"></span>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table>
                                <tbody>
                                { this.renderViewPlanLevelValues(item.level_values) }
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            } else {

                return (

                    <AccordionItem key={index} className="viewPlanDataGroupItem not-available" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item.level_name}
                                <div className="accordion-indicator">Hide<span></span></div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table striped hover>
                                <tbody>
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            }

        });

    }


    renderViewPlanDataSwitcher() {

        if ( this.props.local_view_plan_data ) {

            let local_view_plan_data = this.props.local_view_plan_data;
            let insurees_data = this.props.insurees_data;

            if (local_view_plan_data.insurance_company === "MSH" && insurees_data.destination_country === "États-Unis d’Amérique") {

                return (

                    <Col xs={12} className="viewPlanDataSwitcherBlock">

                        <div className="viewPlanDataSwitcherTitle"><Trans i18nKey={"viewPlans_the_health_title"}/>:</div>

                        <Row className="radioBlockRow">

                            <Col xs={6}>
                                <input
                                    type="radio"
                                    id="data_type_dans"
                                    value="DANS"
                                    name="data_type_radio"
                                    defaultChecked={true}
                                    onChange={this.handleChangeDataTypeForUsaMsh}
                                />
                                <label className="dataTypeRadioLabel" htmlFor="data_type_dans"><Trans i18nKey={"viewPlans_inside_the_health_title"}/></label>
                            </Col>

                            <Col xs={6}>
                                <input
                                    type="radio"
                                    id="data_type_hors"
                                    value="HORS"
                                    name="data_type_radio"
                                    onChange={this.handleChangeDataTypeForUsaMsh}
                                />
                                <label className="dataTypeRadioLabel" htmlFor="data_type_hors"><Trans i18nKey={"viewPlans_outside_the_health_title"}/></label>
                            </Col>

                        </Row>

                    </Col>

                );

            } else {

                return null;

            }
        }

    }


    renderViewPlanServerData() {

        let local_view_plan_data = this.props.local_view_plan_data;
        let server_view_plan_data = this.props.server_view_plan_data;

        // let server_view_plan_data = {
        //     "company": "Allianz",
        //     "company_guarantee_level": "PREMIUM",
        //     "coverage_sum": " 3,000,000 €/$",
        //     "coverage_levels": [
        //         {
        //             "coverage_level_to_match": "H",
        //             "level_name": "Hospitalisation",
        //             "level_values": {
        //                 "Delais d'attente": "Aucun",
        //                 "Chambre d'hopital": "Chambre particulière jusqu’à 350 € par jour, 45 jours max. Chambre semi-privée 100% des frais réels ",
        //                 "Hospitalisation de jour": "100%",
        //                 "Cancer": "100%",
        //                 "Dialyse": "100%",
        //                 "VIH": "100%",
        //                 "Soins paliatifs":  "100%",
        //                 "Hopital Psychiatrique": " Jusqu'à 10 000 €/$, soins de jour uniquement ",
        //                 "Hospitalisation a Domicile": "100%"
        //             }
        //         },
        //         {
        //             "coverage_level_to_match": "RC",
        //             "level_name": "Soins courants",
        //             "level_values": {
        //                 "Delais d'attente": "Aucun",
        //                 "Plafond Annuel Soins courants": "Sans Plafond",
        //                 "Consultation Medecin": "100%",
        //                 "Consultation psychiatre": "10 scéances par an",
        //                 "Medicaments": "100% ",
        //                 "Analyses": "100%",
        //                 "Radiologie/IRM": "100%",
        //                 "Kinesiterapie": "1 000 €/$ par an",
        //                 "Ostheopatie, chiropractie": "100 % des frais réels 50 € par séance, 12 sceances max",
        //                 "Homeopathie, acupuncture, Medecine Chinoise": "MERGE",
        //                 "Bilan de Sante": " 1 200 €/$ par an",
        //                 "Depistages Cancer": "MERGE"
        //             }
        //         },
        //         {
        //             "coverage_level_to_match": "M",
        //             "level_name": "Maternité",
        //             "level_values": {
        //                 "Delais d'attente": "Délai d’attente de 10 mois ",
        //                 "Frais d’accouchement :\n consultations, soins pré et post natals, hospitalisation, chambre privée,\n frais de séjour, honoraires médicaux et chirurgicaux": " jusqu’à 10 000 €/$/ grossesse ",
        //                 "Si accouchement Chirurgical": "MERGE",
        //                 "Plafond complication": "MERGE",
        //                 "Traitement infertilite": "MERGE"
        //             }
        //         },
        //         {
        //             "coverage_level_to_match": "OD",
        //             "level_name": "Dentaire",
        //             "level_values": {
        //                 "Delais d'attente": "Pas de Delais d'attente",
        //                 "Plafond Annuel": " 2500 €/$",
        //                 "Plafond par dent": " 550 €/$ ",
        //                 "Prothese-Implant": "3250 €/$ en plus",
        //                 "Orthodontie < 16 ans": "1700 €/$ par an pendant 3 ans "
        //             }
        //         },
        //         {
        //             "coverage_level_to_match": "OD",
        //             "level_name": "Optique",
        //             "level_values": {
        //                 "Delais d'attente": "Pas de délai d'attente",
        //                 "Montant": " 550 €/$ par an",
        //                 "Lentilles correctrices": "Inclus dans le  plafond",
        //                 "Traitement Laser": "1500 €/$ à vie"
        //             }
        //         }
        //     ]
        // };


        if ( server_view_plan_data ) {

            return (

                <Row className="viewPlanServerDataRow">

                    { this.renderViewPlanDataSwitcher() }

                    <Col xs={12}>

                        <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"viewPlans_sum_title"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data.coverage_sum}
                                {/*{(server_view_plan_data.coverage_sum === "Illimite")*/}
                                {/*    ? "Illimite"*/}
                                {/*    : generateFormattedPrice(server_view_plan_data.coverage_sum, 'EUR')}*/}
                            </Col>

                        </Row>
                        <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"compare_waiting_periods"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data.default_zone}
                                {/*{(server_view_plan_data.coverage_sum === "Illimite")*/}
                                {/*    ? "Illimite"*/}
                                {/*    : generateFormattedPrice(server_view_plan_data.coverage_sum, 'EUR')}*/}
                            </Col>

                        </Row>
                        <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"compare_repatriation_service"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data.repatriation_service}
                                {/*{(server_view_plan_data.coverage_sum === "Illimite")*/}
                                {/*    ? "Illimite"*/}
                                {/*    : generateFormattedPrice(server_view_plan_data.coverage_sum, 'EUR')}*/}
                            </Col>

                        </Row>

                        {(process.env.REACT_APP_LANGUAGE === "ES") ? <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"сobetura_fuera_de_red_de_proveedores"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data["Cobetura fuera de red de proveedores"] === "NON" ? (
                                    // Якщо значення === "NON", виводимо цей JSX
                                    IF_VIEW_PLAN_LEVEL_IS_NON
                                ) : (
                                    // Якщо значення не "NON", виводимо інший JSX
                                    server_view_plan_data["Cobetura fuera de red de proveedores"]
                                )}
                            </Col>

                        </Row> : null }

                        {(process.env.REACT_APP_LANGUAGE === "ES") ? <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"evacuacion_medica_hospitalaria"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data["Evacuación médica hospitalaria"] === "NON" ? (
                                    // Якщо значення === "NON", виводимо цей JSX
                                    IF_VIEW_PLAN_LEVEL_IS_NON
                                ) : (
                                    // Якщо значення не "NON", виводимо інший JSX
                                    server_view_plan_data["Evacuación médica hospitalaria"]
                                )}
                            </Col>

                        </Row> : null }

                        {(process.env.REACT_APP_LANGUAGE === "ES") ? <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"cobertura_de_las_preexistencias_declaradas"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data["Cobertura de las preexistencias declaradas"] === "NON" ? (
                                    // Якщо значення === "NON", виводимо цей JSX
                                    IF_VIEW_PLAN_LEVEL_IS_NON
                                ) : (
                                    // Якщо значення не "NON", виводимо інший JSX
                                    server_view_plan_data["Cobertura de las preexistencias declaradas"]
                                )}
                            </Col>

                        </Row> : null }

                        {(process.env.REACT_APP_LANGUAGE === "ES") ? <Row className="coverageSumRow">

                            <Col className="coverageSumTitle"><Trans i18nKey={"periodo_general_de_espera"}/></Col>
                            <Col className="coverageSumValue">
                                {server_view_plan_data["Período general de espera"] === "NON" ? (
                                    // Якщо значення === "NON", виводимо цей JSX
                                    IF_VIEW_PLAN_LEVEL_IS_NON
                                    ) : (
                                    // Якщо значення не "NON", виводимо інший JSX
                                    server_view_plan_data["Período general de espera"]
                                )}
                            </Col>

                        </Row> : null }

                        <Row>

                            <Accordion
                                allowMultipleExpanded={true}
                                allowZeroExpanded={true}
                                preExpanded={[0, 1, 2, 3, 4]}
                                className="viewPlanDataGroup">

                                {this.renderViewPlanAccordionItem(server_view_plan_data.coverage_levels)}

                            </Accordion>

                        </Row>

                        <Row>

                            {(process.env.REACT_APP_LANGUAGE === "ES") ? <div className="viewPlanRepLiabBlock">{ local_view_plan_data.repatriation_liability_text }</div> : "" }

                        </Row>

                    </Col>

                </Row>

            )

        } else {

            return (

                <div className="loader" style={{textAlign: "center", marginTop: "150px"}}>
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>

            )

        }

    }


    getInsurancePlanExtendedData() {

        if ( this.props.local_view_plan_data ) {

            let local_view_plan_data = this.props.local_view_plan_data;
            let insurees_data = this.props.insurees_data;

            if ( local_view_plan_data.insurance_company === "MSH" && insurees_data.destination_country === "États-Unis d’Amérique" ) {

                this.props.onFetchInsurancePlanExtendedData(
                    this.props.local_view_plan_data.insurance_company,
                    this.props.local_view_plan_data.company_guarantee_level,
                    this.state.data_type_for_USA_MSH,
                    insurees_data.destination_country
                );

            } else {

                this.props.onFetchInsurancePlanExtendedData(
                    this.props.local_view_plan_data.insurance_company,
                    this.props.local_view_plan_data.company_guarantee_level,
                    null,
                    insurees_data.destination_country
                );

            }

        }

    }


    render() {

        let local_view_plan_data = this.props.local_view_plan_data;
        let insurees_data = this.props.insurees_data;

        if ( local_view_plan_data ) {

            return (

                <div className={`viewPlan`+" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>

                    <Container className="defaultContainer viewPlanContainer">

                        <Row className="insurancePlanRow viewPlanRow viewPlanLocalDataRow">

                            <Col className="insurancePlanItem">

                                <Row>

                                    <Col xs={3} className="planLogoSection">

                                        <Row>
                                            <div className="summaryCardGuaranteeLevel">
                                                {renderGuaranteeLevelNameTag(local_view_plan_data.guarantee_level_EA_displayed)}
                                            </div>

                                            <div className="insurancePlanLogoBlock">
                                                <img src={renderInsuranceCompanyLogo(local_view_plan_data.insurance_company)} alt={local_view_plan_data.insurance_company} />
                                            </div>
                                        </Row>

                                    </Col>
                                    <Col xs={3} className="planTypeOfCoverageSection">

                                        {renderTypeOfCoverageList(local_view_plan_data.coverage_types_included, local_view_plan_data.insurance_company, local_view_plan_data.plan_id)}

                                    </Col>
                                    <Col xs={6} className="planPriceSection">

                                        <Row>
                                            <Col xs={12}>
                                                <div className="paymentFrequencyBlock">
                                                    <label><Trans i18nKey={"coveregePage_payment_type_title"}/></label>
                                                    <Select
                                                        value={ (this.props.payment_frequency_value) ? PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value) : null }
                                                        components={{ DropdownIndicator }}
                                                        classNamePrefix="insurance-main-select"
                                                        className="payment-frequency-option"
                                                        onChange={this.handleChangePaymentFrequencyOption}
                                                        options={PAYMENT_FREQUENCY_OPTIONS}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="planPriceBlock">

                                            <Col className="planPriceTitle">
                                                {
                                                    (process.env.REACT_APP_LANGUAGE === "EN")
                                                        ? <>{(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label} <Trans i18nKey="global_price_text"/></>
                                                        : <><Trans i18nKey="global_price_text"/> {(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label.toLowerCase()}</>
                                                }
                                            </Col>
                                            <Col className="planPriceValue">
                                                {
                                                    generateFormattedPrice(
                                                        calculateFinalPrice(
                                                            local_view_plan_data.insurance_company,
                                                            this.props.payment_frequency_value,
                                                            (local_view_plan_data.spreadsheet_rows) ? local_view_plan_data.spreadsheet_rows.length : 0,
                                                            local_view_plan_data.spreadsheet_rows,
                                                            local_view_plan_data.coverage_types_included,
                                                            (local_view_plan_data.deductible_selected_object) ? local_view_plan_data.deductible_selected_object.value : 0,
                                                            (local_view_plan_data.coinsurance_selected_object) ? local_view_plan_data.coinsurance_selected_object.value : 100,
                                                            local_view_plan_data.destination_zone,
                                                            local_view_plan_data.company_guarantee_level,
                                                            this.props.currency,
                                                            insurees_data.destination_country,
                                                            local_view_plan_data.plan_id
                                                        )["current_price"], this.props.currency)
                                                }
                                            </Col>
                                        </Row>

                                        <button
                                            className="mainButtonStyle getPlanButton"
                                            onClick={this.handleGetPlan.bind(this, local_view_plan_data.plan_id)}><Trans i18nKey={"resultPage_get_plan_button"}/></button>

                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                        { this.renderViewPlanServerData() }

                    </Container>

                </div>

            )

        } else {

            return (

                <div className="loader">
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>

            )

        }

    }


}

export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        local_view_plan_data: state.view_plan_data.local_plan_data,
        server_view_plan_data: state.view_plan_data.server_plan_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        payment_frequency_value: state.payment_frequency.payment_frequency_value,
        currency: state.currency_options.currency,
    }),
    dispatch => ({
        onSelectInsurancePlanDataObject: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForGetPlan(plan_data_object));
        },
        onChangePaymentFrequencyValue: (new_payment_frequency_value) => {
            dispatch(changePaymentFrequencyValue(new_payment_frequency_value));
        },
        onFetchInsurancePlanExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country) => {
            dispatch(fetchInsurancePlanExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country));
        }
    })
)(ViewPlan));
